import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store'
import { AuthGetters, AuthPath } from '../store/definitions'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      navigation: {
        icon: 'mdi-home'
      },
      showToGuests: false,
      showToUsers: true,
      showToAdmins: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue'),
    meta: {
      showToGuests: true,
      showToUsers: false,
      showToAdmins: false
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import(/* webpackChunkName: "about" */ '../views/Register.vue'),
    meta: {
      showToGuests: true,
      showToUsers: false,
      showToAdmins: false
    }
  },
  {
    path: '/users',
    name: 'Users',
    component: () => import(/* webpackChunkName: "about" */ '../views/Users.vue'),
    meta: {
      navigation: {
        icon: 'mdi-account-multiple',
        text: 'Mitarbeiter'
      },
      showToGuests: false,
      showToUsers: false,
      showToAdmins: true
    }
  },
  {
    path: '/surveys',
    name: 'Surveys',
    component: () => import(/* webpackChunkName: "about" */ '../views/Surveys.vue'),
    meta: {
      navigation: {
        icon: 'mdi-school',
        text: 'Checklisten'
      },
      showToGuests: false,
      showToUsers: false,
      showToAdmins: true
    }
  },
  {
    path: '/surveys/submissions/:id',
    name: 'Survey Submission',
    component: () => import(/* webpackChunkName: "about" */ '../views/SurveySubmission.vue'),
    meta: {
      showToGuests: false,
      showToUsers: true,
      showToAdmins: true
    }
  },
  {
    path: '/projects',
    name: 'Projects',
    component: () => import(/* webpackChunkName: "about" */ '../views/Projects.vue'),
    meta: {
      navigation: {
        icon: 'mdi-home-group',
        text: 'Projekte'
      },
      showToGuests: false,
      showToUsers: true,
      showToAdmins: true
    }
  },
  {
    path: '/projects/:id',
    name: 'ProjectDetails',
    component: () => import(/* webpackChunkName: "about" */ '../views/ProjectDetails.vue'),
    meta: {
      showToGuests: false,
      showToUsers: true,
      showToAdmins: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  const isAuthenticated = store.getters[AuthPath + '/' + AuthGetters.IS_AUTHENTICATED]
  const isAdmin = store.getters[AuthPath + '/' + AuthGetters.IS_ADMIN]

  if (isAuthenticated) {
    if (to.matched.some(r => r.meta.showToUsers) || (to.matched.some(r => r.meta.showToAdmins && isAdmin))) {
      next()
    } else {
      next({
        path: '/',
      })
    }
  } else {
    if (to.matched.some(r => r.meta.showToGuests)) {
      next()
    } else {
      next({
        path: '/login',
        params: { nextUrl: to.fullPath }
      })
    }
  }
})

router.afterEach((to) => {
  const title = to.meta.navigation ? (to.meta.navigation.text ? to.meta.navigation.text : to.name) : to.name
  document.title = title + " - LSH"
})

export default router
