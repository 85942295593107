export const Actions = {
    NOTIFICATION_SHOW_ERROR: 'NOTIFICATION_SHOW_ERROR',
    NOTIFICATION_SHOW_INFO: 'NOTIFICATION_SHOW_INFO'
}
export const Mutations = {
    NOTIFICATION_SHOW: 'NOTIFICATION_SHOW',
    NOTIFICATION_HIDE: 'NOTIFICATION_HIDE'
}
export const Getters = {
    NOTIFICATION_SHOW: 'NOTIFICATION_SHOW',
    NOTIFICATION_TYPE: 'NOTIFICATION_TYPE',
    NOTIFICATION_TEXT: 'NOTIFICATION_TEXT'
}
export const NOTIFICATION_TYPE = {
    ERROR: 'ERROR',
    INFO: 'INFO'
}

export const AuthPath = 'auth'
export const AuthActions = {
    REFRESH: 'AUTH_REFRESH',
    REQUEST: 'AUTH_REQUEST',
    REGISTER: 'AUTH_REGISTER',
    LOGOUT: 'AUTH_LOGOUT',
    REFRESH_DATA: 'AUTH_REFRESH_DATA',
    SWITCH_ROLE: 'AUTH_SWITCH_ROLE',
    SET_PASSWORD: 'AUTH_SET_PASSWORD'
}
export const AuthMutations = {
    REQUEST: 'AUTH_REQUEST',
    SUCCESS: 'AUTH_SUCCESS',
    SET_DATA: 'AUTH_SET_DATA',
    SET_ROLE: 'AUTH_SET_ROLE',
    ERROR: 'AUTH_ERROR',
    LOGOUT: 'AUTH_LOGOUT'
}
export const AuthGetters = {
    IS_AUTHENTICATED: 'AUTH_IS_AUTHENTICATED',
    STATUS: 'AUTH_STATUS',
    CAN_BE_ADMIN: 'AUTH_CAN_BE_ADMIN',
    IS_ADMIN: 'AUTH_IS_ADMIN',
    IS_USER: 'AUTH_IS_USER',
    USERID: 'AUTH_USERID'
}

export const UsersPath = 'users'
export const UsersActions = {
    REFRESH: 'USERS_REFRESH',
    CREATE: 'USERS_CREATE',
    EDIT: 'USERS_EDIT',
    RESET: 'USERS_RESET',
    REMOVE_USER: 'USERS_REMOVE_USER',
    RESET_PIN: 'USERS_RESET_PIN'
}
export const UsersMutations = {
    REFRESH: 'USERS_REFRESH',
    ADD: 'USERS_ADD',
    CLEAR: 'USERS_CLEAR'
}
export const UsersGetters = {
    USERS: 'USERS_USERS',
    USER_BY_ID: 'USERS_USER_BY_ID'
}

export const ProjectsPath = 'projects'
export const ProjectsActions = {
    REFRESH: 'PROJECTS_REFRESH',
    REFRESH_USERS: 'PROJECTS_REFRESH_USERS',
    REFRESH_ROLES: 'PROJECTS_REFRESH_ROLES',
    REFRESH_ROLEREQUIREMENTS: 'PROJECT_REFRESH_ROLEREQUIREMENTS',
    RESET: 'PROJECTS_RESET',
    ADD_PROJECT: 'PROJECTS_ADD_PROJECT',
    REMOVE_PROJECT: 'PROJECTS_REMOVE_PROJECT',
    ADD_PROJECT_USER: 'PROJECTS_ADD_PROJECT_USER',
    REMOVE_PROJECT_USER: 'PROJECTS_REMOVE_PROJECT_USER',
    ADD_PROJECT_ROLE_REQUIREMENT: 'PROJECTS_ADD_PROJECT_SURVEY',
    REMOVE_PROJECT_ROLE_REQUIREMENT: 'PROJECTS_REMOVE_PROJECT_SURVEY'
}
export const ProjectsMutations = {
    REFRESH: 'PROJECTS_REFRESH',
    REFRESH_USERS: 'PROJECTS_REFRESH_USERS',
    REFRESH_ROLES: 'PROJECTS_REFRESH_ROLES',
    REFRESH_ROLEREQUIREMENTS: 'PROJECTS_REFRESH_ROLEREQUIREMENTS',
    CLEAR: 'PROJECTS_CLEAR'
}
export const ProjectsGetters = {
    PROJECTS: 'PROJECTS_PROJECTS',
    PROJECTS_BY_USER_ID: 'PROJECTS_PROJECTS_BY_USER_ID',
    PROJECT_IDS_BY_USER_ID: 'PROJECTS_PROJECT_IDS_BY_USER_ID',
    USERS_BY_PROJECT_ID: 'PROJECTS_USERS_BY_PROJECT_ID',
    ROLES_BY_PROJECT_ID: 'PROJECTS_ROLES_BY_PROJECT_ID',
    ROLEREQUIREMENTS_BY_PROJECT_ID: 'PROJECTS_ROLEREQUIREMENTS_BY_PROJECT_ID',
}

export const SurveysPath = 'surveys'
export const SurveysActions = {
    REFRESH: 'SURVEYS_REFRESH',
    REFRESH_QUESTIONS: 'SURVEYS_REFRESH_QUESTIONS',
    REFRESH_OPTIONS: 'SURVEYS_REFRESH_OPTIONS',
    REFRESH_SUBMISSIONS: 'SURVEYS_REFRESH_SUBMISSIONS',
    RESET: 'SURVEYS_RESET',
    DUPLICATE_SURVEY: 'SURVEYS_DUPLICATE_SURVEY',
    ADD_SURVEY: 'SURVEYS_ADD_SURVEY',
    ADD_QUESTION: 'SURVEYS_ADD_QUESTION',
    ADD_OPTION: 'SURVEYS_ADD_OPTION',
    ADD_SUBMISSION: 'SURVEYS_ADD_SUBMISSION',
    ADD_SUBMISSION_OPTION: 'SURVEYS_ADD_SUBMISSION_OPTION',
    EDIT_SURVEY: 'SURVEYS_EDIT_SURVEY',
    EDIT_QUESTION: 'SURVEYS_EDIT_QUESTION',
    EDIT_OPTION: 'SURVEYS_EDIT_OPTION',
    EDIT_SUBMISSION: 'SURVEYS_EDIT_SUBMISSION',
    REMOVE_SURVEY: 'SURVEYS_REMOVE_SURVEY',
    REMOVE_QUESTION: 'SURVEYS_REMOVE_QUESTION',
    REMOVE_OPTION: 'SURVEYS_REMOVE_OPTION',
    REMOVE_SUBMISSION: 'SURVEYS_REMOVE_SUBMISSION'
}
export const SurveysMutations = {
    REFRESH: 'SURVEYS_REFRESH',
    REFRESH_QUESTIONS: 'SURVEYS_REFRESH_QUESTIONS',
    REFRESH_OPTIONS: 'SURVEYS_REFRESH_OPTIONS',
    REFRESH_SUBMISSIONS: 'SURVEYS_REFRESH_SUBMISSIONS',
    CLEAR: 'SURVEYS_CLEAR'
}
export const SurveysGetters = {
    SURVEY_BY_ID: 'SURVEYS_SURVEY_BY_ID',
    QUESTIONS_BY_SURVEY_ID: 'SURVEYS_QUESTIONS_BY_SURVEY_ID',
    OPTIONS_BY_QUESTION_ID: 'SURVEYS_OPTIONS_BY_QUESTION_ID',
    SUBMISSION_BY_ID: 'SURVEYS_SUBMISSIONS_BY_ID',
    SUBMISSIONS_BY_USER_ID: 'SURVEYS_SUBMISSIONS_BY_USER_ID',
    SUBMISSIONS_BY_SURVEY_ID: 'SURVEYS_SUBMISSIONS_BY_SURVEY_ID',
    SUBMISSIONS_BY_PRRS:'SURVEYS_SUBMISSIONS_BY_PRRS' 
}