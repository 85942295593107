<template>
  <v-app>
    <v-app-bar
      v-if="isAuthenticated"
      app clipped-left
      color="primary"
      dense
    >
      <v-app-bar-nav-icon color="white" @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title class="white--text">LSH Feuerfestbau</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-alert
        v-if="notificationShow"
        dense
        border="top"
        :color="notificationColor"
        class="mt-4 mr-0"
        style="color: white"
      >{{ notificationText }}</v-alert>
    </v-app-bar>
    <Navigation
      :show="drawer"
      @transitionend="navigationTransitionEnd"
    ></Navigation>
    <v-main>
      <router-view class="fill-height"/>
    </v-main>
  </v-app>
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { AuthActions, AuthGetters, AuthPath, Getters, NOTIFICATION_TYPE } from './store/definitions';
import Navigation from './views/Navigation.vue'

export default {
  components: {
    Navigation
  },

  mounted: function() {
    this[AuthActions.REFRESH]();
  },

  data: () => ({
    drawer: false
  }),

  computed: {
    ...mapState(AuthPath, { 'authData' : 'data' }),
    ...mapGetters(AuthPath, { isAuthenticated: AuthGetters.IS_AUTHENTICATED }),
    ...mapGetters({
      notificationShow: Getters.NOTIFICATION_SHOW,
      notificationType: Getters.NOTIFICATION_TYPE,
      notificationText: Getters.NOTIFICATION_TEXT
    }),

    notificationColor: function() {
      const type = this.notificationType
      return type == NOTIFICATION_TYPE.INFO ? 'green' : 'red'
    }
  },

  watch: {
    isAuthenticated: function(val) {
      if (!val) {
        this.$router.go();
      }
    }
  },

  methods: {
    navigationTransitionEnd: function(payload) {
      this.drawer = payload;
    },

    ...mapActions(AuthPath, [ AuthActions.REFRESH ])
  }
}
</script>
