<template>
    <v-navigation-drawer
      v-if="authData !== ''"
      v-model="drawer"
      clipped
      app
      v-on:transitionend="transitionend"
      width="250"
    >
      <v-container v-if="authData !== ''">
      <v-img
        :src="require('../assets/logo_notext.png')"
        class="my-3"
        contain
        height="100"
      />
        <v-card-title>{{ authData.firstName }} {{ authData.name }}</v-card-title>
        <v-card-subtitle class="text-left">
          <button v-if="canBeAdmin" @click="authSwitchRole()">{{ isAdmin ? 'Administrator' : 'Mitarbeiter' }}</button><br v-if="canBeAdmin"/>
          <button @click="logout()">Abmelden</button>
        </v-card-subtitle>
      </v-container>

      <v-list>
        <v-list-item v-for="route in routes"
          :key="route.name" :to="route.path">
          <v-list-item-icon>
            <v-icon>{{ route.meta.navigation.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="text-left">{{ route.meta.navigation.text !== undefined ? route.meta.navigation.text : route.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-for="project in projects" :key="project.id"
          :to="'/projects/' + project.id">
          <v-list-item-icon class="ml-4">
            <v-icon small>mdi-wall</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="text-left">{{ project.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

      </v-list>
    </v-navigation-drawer>

</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { AuthActions, AuthGetters, AuthPath, ProjectsGetters, ProjectsPath } from '../store/definitions';
// @ is an alias to /src

export default {
  name: 'Navigation',

  props: [ 'show' ],

  mounted: function() {
  },

  updated: function() {
    this.transitionend();
  },

  data: () => ({
    drawer: null,
    projectsExpanded: true,
  }),

  computed: {
    routes() {
      let routes = this.$router.options.routes
        .filter(r => r.meta.navigation !== undefined);
        routes = routes.filter(r =>
          (r.meta.showToGuest && !this.isAuthenticated) ||
          (r.meta.showToUsers && this.isUser) ||
          (r.meta.showToAdmins && this.isAdmin))
      return routes;
    },

    authData () {
      return this.$store.state.auth.data;
    },
    
    ...mapGetters(AuthPath, {
      isAuthenticated: AuthGetters.IS_AUTHENTICATED,
      canBeAdmin: AuthGetters.CAN_BE_ADMIN,
      isAdmin: AuthGetters.IS_ADMIN,
      isUser: AuthGetters.IS_USER
    }),

    projects() {
      const authData = this.authData;
      const userId = authData.id;
      if (this.isAdmin) {
        return this[ProjectsGetters.PROJECTS];
      }
      return this[ProjectsGetters.PROJECTS_BY_USER_ID](userId);
    },

    ...mapGetters(ProjectsPath, [ ProjectsGetters.PROJECTS_BY_USER_ID, ProjectsGetters.PROJECTS ])
  },

  watch: {
    show: function(val) {
      this.drawer = val;
    }
  },

  methods: {
    transitionend: function() {
      this.$emit('transitionend', this.drawer);
    },

    ...mapActions(AuthPath, {
      logout: AuthActions.LOGOUT,
      authSwitchRole: AuthActions.SWITCH_ROLE
    })
  },

  components: {
  }
}
</script>
