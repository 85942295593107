import Vue from 'vue'
import Vuex from 'vuex'
import userStore from './users'
import projectStore from './projects'
import surveyStore from './surveys'
import authStore from './auth'
import { AuthPath, UsersPath, ProjectsPath, AuthActions, Mutations, Getters, Actions, SurveysPath, NOTIFICATION_TYPE } from './definitions'
import axios from 'axios'


Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        notificationShow: false,
        notificationType: NOTIFICATION_TYPE.INFO,
        notificationText: ''
    },

    modules: {
        [UsersPath]: userStore,
        [ProjectsPath]: projectStore,
        [SurveysPath]: surveyStore,
        [AuthPath]: authStore
    },

    getters: {
        [Getters.NOTIFICATION_SHOW]: state => state.notificationShow,
        [Getters.NOTIFICATION_TYPE]: state => state.notificationType,
        [Getters.NOTIFICATION_TEXT]: state => state.notificationText
    },

    actions: {
        [Actions.NOTIFICATION_SHOW_ERROR]: ( { commit }, text) => {
            return new Promise((resolve) => {
                commit(Mutations.NOTIFICATION_SHOW, { text, type: NOTIFICATION_TYPE.ERROR })
                new Promise(resolveTimer => setTimeout(resolveTimer, 3000))
                .then(() => {
                    commit(Mutations.NOTIFICATION_HIDE)
                    resolve()
                })
            })
        },
        
        [Actions.NOTIFICATION_SHOW_INFO]: ( { commit }, text) => {
            return new Promise((resolve) => {
                commit(Mutations.NOTIFICATION_SHOW, { text, type: NOTIFICATION_TYPE.INFO })
                new Promise(resolveTimer => setTimeout(resolveTimer, 3000))
                .then(() => {
                    commit(Mutations.NOTIFICATION_HIDE)
                    resolve()
                })
            })
        }
    },

    mutations: {
        [Mutations.NOTIFICATION_SHOW]: (state, { text, type }) => {
            state.notificationShow = true
            state.notificationText = text
            state.notificationType = type
        },
        [Mutations.NOTIFICATION_HIDE]: (state) => {
            state.notificationShow = false
            state.notificationText = ''
            state.notificationType = NOTIFICATION_TYPE.INFO
        },
    }
})

axios.interceptors.response.use(response => response, error => {
    if (error.response.status === 401) {
        store.dispatch(AuthPath + '/' + AuthActions.LOGOUT)
    }
    throw error
})


export default store