import axios from 'axios'
import { SurveysActions, SurveysGetters, SurveysMutations } from './definitions';

const surveyStore = {
	namespaced: true,
	state: {
	surveys: [],
	surveyQuestions: [],
	surveyOptions: [],
	surveySubmissions: [],
	surveySubmissionOptions: []
	},

	actions: {
    [SurveysActions.REFRESH]: ({ commit }) => {
      return new Promise((resolve, reject) => {
        axios({url: '/surveys', method: 'GET' })
          .then(resp => {
          const surveys = resp.data.data;
          commit(SurveysMutations.REFRESH, surveys)
          resolve(resp)
          })
        .catch(err => {
          reject(err)
        })
      })
    },
    
    [SurveysActions.REFRESH_QUESTIONS]: ({ commit }) => {
      return new Promise((resolve, reject) => {
        axios({url: '/surveyQuestions', method: 'GET' })
          .then(resp => {
            const surveyQuestions = resp.data.data;
            commit(SurveysMutations.REFRESH_QUESTIONS, surveyQuestions)
            resolve(resp)
          })
        .catch(err => {
          reject(err)
        })
      })
    },
    
    [SurveysActions.REFRESH_OPTIONS]: ({ commit }) => {
      return new Promise((resolve, reject) => {
        axios({url: '/surveyOptions', method: 'GET' })
          .then(resp => {
            const surveyOptions = resp.data.data;
            commit(SurveysMutations.REFRESH_OPTIONS, surveyOptions)
            resolve(resp)
          })
        .catch(err => {
          reject(err)
        })
      })
    },
    
    [SurveysActions.REFRESH_SUBMISSIONS]: ({ commit }) => {
      return new Promise((resolve, reject) => {
        axios({url: '/surveySubmissions', method: 'GET' })
          .then(resp => {
            const surveySubmissions = resp.data.data;
            commit(SurveysMutations.REFRESH_SUBMISSIONS, surveySubmissions)
            resolve(resp)
          })
        .catch(err => {
          reject(err)
        })
      })
    },

    [SurveysActions.RESET]: ({ commit }) => new Promise(resolve => {
      commit(SurveysMutations.CLEAR);
      resolve();
    }),

    [SurveysActions.ADD_SURVEY]: ({ dispatch }, survey) => {
      return new Promise((resolve, reject) => {
        axios({url: '/surveys', data: survey, method: 'POST' })
        .then((resp) => {
          dispatch(SurveysActions.REFRESH)
          .then(() => resolve(resp.data.data))
        })
        .catch(err => {
          reject(err)
        })
      })
    },

    [SurveysActions.EDIT_SURVEY]: (_, payload) => {
        return new Promise((resolve, reject) => {
            payload.token = null;
            axios({url: '/surveys/' + payload.id, data: payload, method: "PUT"})
                .then(() => {
                    resolve();
                })
                .catch(err => {
                    reject(err);
                })
        })
    },

    [SurveysActions.REMOVE_SURVEY]: ({ dispatch }, surveyId) => {
      return new Promise((resolve, reject) => {
        axios({url: `/surveys/${surveyId}`, method: 'DELETE' })
        .then(() => {
          dispatch(SurveysActions.REFRESH)
          .then(() => resolve())
        })
        .catch(err => {
          reject(err)
        })
      })
    },

    [SurveysActions.DUPLICATE_SURVEY]: ({ dispatch, getters }, surveyId) => new Promise(() => {
      dispatch(SurveysActions.REFRESH)
      .then(() => dispatch(SurveysActions.REFRESH_QUESTIONS)
      .then(() => dispatch(SurveysActions.REFRESH_OPTIONS)
      .then(() => {
        const survey = { ...getters[SurveysGetters.SURVEY_BY_ID](surveyId) }
        survey.name = `${survey.name} (Kopie)`
        const questions = getters[SurveysGetters.QUESTIONS_BY_SURVEY_ID](surveyId)
        dispatch(SurveysActions.ADD_SURVEY, survey)

        .then((duplicatedSurvey) => {
          for (const { ...question } of questions) {
            question.surveyId = duplicatedSurvey.id
            const options = getters[SurveysGetters.OPTIONS_BY_QUESTION_ID](question.id)
            dispatch(SurveysActions.ADD_QUESTION, question)

            .then((duplicatedQuestion) => {
              for (const { ...option} of options) {
                option.surveyQuestionId = duplicatedQuestion.id
                dispatch(SurveysActions.ADD_OPTION, option)
              }
            })
          }
        })
      })))
    }),

    [SurveysActions.ADD_QUESTION]: ({ dispatch }, question) => {
      return new Promise((resolve, reject) => {
        axios({url: '/surveyQuestions', data: question, method: 'POST' })
        .then((resp) => {
          dispatch(SurveysActions.REFRESH_QUESTIONS)
          .then(() => resolve(resp.data.data))
        })
        .catch(err => {
          reject(err)
        })
      })
    },

    [SurveysActions.ADD_OPTION]: ({ dispatch }, option) => {
      return new Promise((resolve, reject) => {
        axios({url: '/surveyOptions', data: option, method: 'POST' })
        .then((resp) => {
          dispatch(SurveysActions.REFRESH_OPTIONS)
          .then(() => resolve(resp.data.data))
        })
        .catch(err => {
          reject(err)
        })
      })
    },

    [SurveysActions.EDIT_QUESTION]: (_, payload) => {
        return new Promise((resolve, reject) => {
            payload.token = null;
            axios({url: '/surveyQuestions/' + payload.id, data: payload, method: "PUT"})
                .then(() => {
                    resolve();
                })
                .catch(err => {
                    reject(err);
                })
        })
    },

    [SurveysActions.REMOVE_QUESTION]: ({ dispatch }, questionId) => {
      return new Promise((resolve, reject) => {
        axios({url: `/surveyQuestions/${questionId}`, method: 'DELETE' })
        .then(() => {
          dispatch(SurveysActions.REFRESH_QUESTIONS)
          .then(() => resolve())
        })
        .catch(err => {
          reject(err)
        })
      })
    },

    [SurveysActions.EDIT_OPTION]: (_, payload) => {
        return new Promise((resolve, reject) => {
            payload.token = null;
            axios({url: '/surveyOptions/' + payload.id, data: payload, method: "PUT"})
                .then(() => {
                    resolve();
                })
                .catch(err => {
                    reject(err);
                })
        })
    },

    [SurveysActions.REMOVE_OPTION]: ({ dispatch }, optionId) => {
      return new Promise((resolve, reject) => {
        axios({url: `/surveyOptions/${optionId}`, method: 'DELETE' })
        .then(() => {
          dispatch(SurveysActions.REFRESH_OPTIONS)
          .then(() => resolve())
        })
        .catch(err => {
          reject(err)
        })
      })
    },

    [SurveysActions.ADD_SUBMISSION]: ({ dispatch }, submission) => {
      return new Promise((resolve, reject) => {
        axios({url: '/surveySubmissions', data: submission, method: 'POST' })
        .then((resp) => {
          dispatch(SurveysActions.REFRESH_SUBMISSIONS)
          .then(() => resolve(resp.data.data))
        })
        .catch(err => {
          reject(err)
        })
      })
    },

    [SurveysActions.REMOVE_SUBMISSION]: ({ dispatch }, submissionId) => {
      return new Promise((resolve, reject) => {
        axios({url: `/surveySubmissions/${submissionId}`, method: 'DELETE' })
        .then((resp) => {
          dispatch(SurveysActions.REFRESH_SUBMISSIONS)
          .then(() => resolve(resp.data.data))
        })
        .catch(err => {
          reject(err)
        })
      })
    },
	},
	
	mutations: {
    [SurveysMutations.REFRESH]: function(state, payload) {
      state.surveys = [];
      for (const survey of payload) {
          state.surveys.push(survey)
      }
    },
      
    [SurveysMutations.REFRESH_QUESTIONS]: function(state, payload) {
      state.surveyQuestions = [];
      for (const surveyQuestion of payload) {
          state.surveyQuestions.push(surveyQuestion)
      }
    },

    [SurveysMutations.REFRESH_OPTIONS]: function(state, payload) {
      state.surveyOptions = [];
      for (const surveyOption of payload) {
          state.surveyOptions.push(surveyOption)
      }
    },

    [SurveysMutations.REFRESH_SUBMISSIONS]: function(state, payload) {
      state.surveySubmissions = [];
      for (const surveySubmission of payload) {
          state.surveySubmissions.push(surveySubmission)
      }
    },

    [SurveysMutations.CLEAR]: function(state) {
      state.surveys = [];
      state.surveyQuestions = [];
      state.surveyOptions = [];
    }
	},

	getters: {
    [SurveysGetters.SURVEY_BY_ID]: (state) => (surveyId) =>
      state.surveys.find(s => s.id == surveyId),

    [SurveysGetters.QUESTIONS_BY_SURVEY_ID]: (state) => (surveyId) => 
      state.surveyQuestions.filter(sq => sq.surveyId == surveyId),
      
    [SurveysGetters.OPTIONS_BY_QUESTION_ID]: (state) => (questionId) =>
      state.surveyOptions.filter(so => so.surveyQuestionId == questionId),
    
    [SurveysGetters.SUBMISSION_BY_ID]: (state) => (submissionId) =>
      state.surveySubmissions.find(ss => ss.id == submissionId),
  
    [SurveysGetters.SUBMISSIONS_BY_USER_ID]: (state) => (userId) =>
      state.surveySubmissions.filter(ss => ss.userId == userId),

    [SurveysGetters.SUBMISSIONS_BY_PRRS]: (state, getters, rootState) => (prrs) => {
      let subs = state.surveySubmissions.filter(ss => prrs.find(prr => prr.surveyId == ss.surveyId) != undefined)
      let result = subs.map(ss => {
        let sub = {...ss}
        let prr = prrs.find(prr => prr.surveyId == sub.surveyId)
        sub.category = prr.name
        let user = rootState.users.users.find(u => u.id == sub.userId)
        sub.author = user.name + ' ' + user.firstName
        return sub
      })
      result.push(...prrs.filter(prr => result.find(ss => prr.surveyId == ss.surveyId) === undefined).map(prr => {
        return { category: prr.name }
      }))
      return result
    },
	}
}

export default surveyStore