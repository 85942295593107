<template>
  <div class="home">
    <v-card-title v-if="!isAutenticated">Zugriff verweigert</v-card-title>
    <v-card-title v-else>Übersicht für {{ data.firstName }} {{ data.name }}</v-card-title>
    <v-form class="ml-4 mr-4">
      <v-row>
        <v-col md="3" cols="6">
          <v-text-field
            label="E-Mail"
            v-model="data.email"
          ></v-text-field>
        </v-col>
        <v-col md="3" cols="6">
          <v-text-field
            label="Passwort"
            v-model="password"
            :prepend-icon="showPassword ? 'mdi-close' : ''"
            @click:prepend="showPassword = false; password = passwordPlaceholder"
            :append-icon="showPassword ? 'mdi-send' : 'mdi-pencil'"
            @click:append="clickShowPassword"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-form>

    <v-row>
      <v-col md="6" cols="12">
        <v-card-title>Projekte</v-card-title>
        <v-card-text class="text-left">Schulungsstatistiken über Projekte</v-card-text>
        <v-data-table
          mobile-breakpoint="0"
          :headers="projectsHeaders"
          :items="projects"
          @click:row="clickProject"
        ></v-data-table>
      </v-col>
      
      <v-col md="6" cols="12">
        <v-card-title>Mitarbeiter</v-card-title>
        <v-card-text class="text-left">Schulungsstatistiken über Mitarbeiter</v-card-text>
        <v-data-table
          mobile-breakpoint="0"
          :headers="usersHeaders"
          :items="users"
        >
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { AuthActions, AuthGetters, AuthPath, ProjectsActions, ProjectsPath, UsersActions, UsersGetters, UsersPath } from '../store/definitions'
// @ is an alias to /src

export default {
  name: 'HomeAdmin',

  mounted: function() {
    this[AuthActions.REFRESH]();
    this[UsersActions.REFRESH]();
    this[ProjectsActions.REFRESH]();
    this.password = this.passwordPlaceholder
  },

  data: () => {
    return {
      showPassword: false,
      password: '',
      passwordPlaceholder: '****************************************',
      usersHeaders: [
        { text: 'Name', align: 'start', sortable: true, value: 'name' },
        { text: 'Vorname', align: 'start', sortable: true, value: 'firstName' },
        { text: 'Status', align: 'end', sortable: false, value: 'status' },
      ],
      projectsHeaders: [
        { text: 'Name', align: 'start', sortable: true, value: 'name' },
        { text: 'Status', align: 'end', sortable: false, value: 'status' },
      ]
    }
  },

  computed: {
    ...mapState(AuthPath, [ 'data' ]),
    ...mapGetters(AuthPath, { isAutenticated: AuthGetters.IS_AUTHENTICATED }),
    ...mapGetters(UsersPath, { 'users': UsersGetters.USERS }),
    ...mapState(ProjectsPath, { 'projects': 'projects' })
  },

  methods: {
    clickShowPassword: function() {
      if (this.showPassword) {
        this[AuthActions.SET_PASSWORD](this.password)
        this.password = this.passwordPlaceholder
        this.showPassword = false
      } else {
        this.password = ''
        this.showPassword = true
      }
    },

    clickProject: function(project) {
      this.$router.push(`/projects/${project.id}`)
    },
    
    ...mapActions(AuthPath, [
      AuthActions.REFRESH,
      AuthActions.SET_PASSWORD
    ]),
    ...mapActions(UsersPath, [ UsersActions.REFRESH ]),
    ...mapActions(ProjectsPath, [ ProjectsActions.REFRESH ])
  }
}
</script>
