import axios from 'axios'
import { ProjectsActions, ProjectsGetters, ProjectsMutations } from './definitions';

const projectStore = {
    namespaced: true,
    state: {
        projects: [],
        projectUsers: [],
        projectRoles: [],
        projectRoleRequirements: []
    },

    actions: {
      [ProjectsActions.REFRESH]: ({ commit }) => {
          return new Promise((resolve, reject) => {
              axios({url: '/projects', method: 'GET' })
                .then(resp => {
                  const projects = resp.data.data;
                  commit(ProjectsMutations.REFRESH, projects)
                  resolve(resp)
                })
              .catch(err => {
                reject(err)
              })
            })
      },
      [ProjectsActions.REFRESH_USERS]: ({ dispatch, commit }) => {
          return new Promise((resolve, reject) => {
            dispatch(ProjectsActions.REFRESH_ROLES)
              .then(rolesResp => {
                axios({url: '/projectUsers', method: 'GET' })
                  .then(resp => {
                    const users = resp.data.data;
                    for (const user of users) {
                      user.roleName = rolesResp.data.data.find(role => role.id === user.roleId).roleName
                    }
                    commit(ProjectsMutations.REFRESH_USERS, users)
                    resolve(resp)
                  })
                .catch(err => {
                  reject(err)
                })
              })
              .catch(err => {
                reject(err)
              })
            })
      },
      [ProjectsActions.REFRESH_ROLES]: ({ commit }) => {
          return new Promise((resolve, reject) => {
              axios({url: '/projectRoles', method: 'GET' })
                .then(resp => {
                  const roles = resp.data.data;
                  commit(ProjectsMutations.REFRESH_ROLES, roles)
                  resolve(resp)
                })
              .catch(err => {
                reject(err)
              })
            })
      },
      [ProjectsActions.REFRESH_ROLEREQUIREMENTS]: ({ commit }) => {
          return new Promise((resolve, reject) => {
              axios({url: '/projectRoleRequirements', method: 'GET' })
                .then(resp => {
                  const roleRequirements = resp.data.data;
                  commit(ProjectsMutations.REFRESH_ROLEREQUIREMENTS, roleRequirements)
                  resolve(resp)
                })
              .catch(err => {
                reject(err)
              })
            })
      },

      [ProjectsActions.ADD_PROJECT]: ({ dispatch }, project) => {
        return new Promise((resolve, reject) => {
          axios({url: '/projects', data: project, method: 'POST' })
            .then(() => {
              dispatch(ProjectsActions.REFRESH)
              .then(() => resolve())
            })
          .catch(err => {
            reject(err)
          })
        })
      },

      [ProjectsActions.REMOVE_PROJECT]: ({ dispatch }, projectId) => {
        return new Promise((resolve, reject) => {
          axios({url: `/projects/${projectId}`, method: 'DELETE' })
            .then(() => {
              dispatch(ProjectsActions.REFRESH)
              .then(() => resolve())
            })
          .catch(err => {
            reject(err)
          })
        })
      },

      [ProjectsActions.ADD_PROJECT_USER]: ({ dispatch }, projectUser) => {
        return new Promise((resolve, reject) => {
          axios({url: '/projectUsers', data: projectUser, method: 'POST' })
            .then(() => {
              dispatch(ProjectsActions.REFRESH_USERS)
              .then(() => resolve())
            })
          .catch(err => {
            reject(err)
          })
        })
      },

      [ProjectsActions.REMOVE_PROJECT_USER]: ({ dispatch }, projectUserId) => {
        return new Promise((resolve, reject) => {
          axios({url: `/projectUsers/${projectUserId}`, method: 'DELETE' })
            .then(() => {
              dispatch(ProjectsActions.REFRESH_USERS)
              .then(() => resolve())
            })
          .catch(err => {
            reject(err)
          })
        })
      },

      [ProjectsActions.ADD_PROJECT_ROLE_REQUIREMENT]: ({ dispatch }, prr) => {
        return new Promise((resolve, reject) => {
          axios({url: '/projectRoleRequirements', data: prr, method: 'POST' })
            .then(() => {
              dispatch(ProjectsActions.REFRESH_ROLEREQUIREMENTS)
              .then(() => resolve())
            })
          .catch(err => {
            reject(err)
          })
        })
      },

      [ProjectsActions.REMOVE_PROJECT_ROLE_REQUIREMENT]: ({ dispatch }, prrId) => {
        return new Promise((resolve, reject) => {
          axios({url: `/projectRoleRequirements/${prrId}`, method: 'DELETE' })
            .then(() => {
              dispatch(ProjectsActions.REFRESH)
              .then(() => resolve())
            })
          .catch(err => {
            reject(err)
          })
        })
      },
      
      [ProjectsActions.RESET]: ({ commit }) => new Promise(resolve => {
        commit(ProjectsMutations.CLEAR);
        resolve();
      })
    },

    mutations: {
      [ProjectsMutations.REFRESH]: function(state, payload) {
          state.projects = [];
          for (const project of payload) {
            state.projects.push(project)
          }
      },

      [ProjectsMutations.REFRESH_USERS]: function(state, payload) {
          state.projectUsers = [];
          for (const projectUser of payload) {
              state.projectUsers.push(projectUser)
          }
      },

      [ProjectsMutations.REFRESH_ROLES]: function(state, payload) {
          state.projectRoles = [];
          for (const projectRole of payload) {
              state.projectRoles.push(projectRole)
          }
      },

      [ProjectsMutations.REFRESH_ROLEREQUIREMENTS]: function(state, payload) {
          state.projectRoleRequirements = [];
          for (const projectRoleRequirement of payload) {
              state.projectRoleRequirements.push(projectRoleRequirement)
          }
      },

      [ProjectsMutations.CLEAR]: function(state) {
        state.projects = [];
        state.projectUsers = [];
      }
    },

    getters: {
      [ProjectsGetters.PROJECTS]: (state) => {
        return state.projects;
      },

      [ProjectsGetters.PROJECTS_BY_USER_ID]: (state, getters) => (userId) => {
        const projectIdsWithDuplicates = getters[ProjectsGetters.PROJECT_IDS_BY_USER_ID](userId);
        const projectIds = [...new Set(projectIdsWithDuplicates)];
        const res = projectIds.map(pi => state.projects.find(p => p.id == pi)).filter(e => e != undefined);
        return res;
      },

      [ProjectsGetters.PROJECT_IDS_BY_USER_ID]: (state) => (userId) => {
        const projectUsers = state.projectUsers.filter(pu => pu.userId == userId);
        return projectUsers.map(pu => state.projectRoles.find(pr => pr.id === pu.roleId).projectId);
      },

      [ProjectsGetters.ROLES_BY_PROJECT_ID]: (state) => (projectId) => {
        return state.projectRoles.filter(pr => pr.projectId === projectId);
      },

      [ProjectsGetters.ROLEREQUIREMENTS_BY_PROJECT_ID]: (state, getters) => (projectId) => {
        if (projectId === undefined) {
          return []
        }
        let roles = getters[ProjectsGetters.ROLES_BY_PROJECT_ID](projectId);
        return state.projectRoleRequirements.filter(prr => roles.find(pr => prr.roleId === pr.id) != undefined);
      },

      [ProjectsGetters.USERS_BY_PROJECT_ID]: (state, getters, rootState) => (projectId) => {
        const projectRoles = getters[ProjectsGetters.ROLES_BY_PROJECT_ID](projectId);
        const projectUsers = state.projectUsers.filter(pu => projectRoles.find(pr => pr.id === pu.roleId) != undefined);
        return projectUsers.map(pu => {
          let user = { ...rootState.users.users.find(u => u.id === pu.userId) }
          if (!user) {
            return false
          }
          user.projectUserId = pu.id
          user.role = projectRoles.find(pr => pr.id == pu.roleId).roleName
          return user
        }).filter(Boolean)
      },
    }
}

export default projectStore;