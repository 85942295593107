<template>
  <home-admin v-if="isAdmin"/>
  <home-user v-else-if="isUser"/>
  <div v-else class="text-center">
    <v-progress-circular
      indeterminate
      color="primary"
      class="mt-12"
      size="150"
    >Laden...</v-progress-circular>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { AuthActions, AuthGetters, AuthPath } from '../store/definitions'
import HomeAdmin from '../components/HomeAdmin.vue';
import HomeUser from '../components/HomeUser.vue';
// @ is an alias to /src

export default {
  components: { HomeAdmin, HomeUser },
  name: 'Home',

  mounted: function() {
    this[AuthActions.REFRESH]();
  },

  computed: {
    ...mapGetters(AuthPath, {
      isAutenticated: AuthGetters.IS_AUTHENTICATED,
      isAdmin: AuthGetters.IS_ADMIN,
      isUser: AuthGetters.IS_USER
      }),
  },

  methods: {
    ...mapActions(AuthPath, [ AuthActions.REFRESH ])
  }
}
</script>
